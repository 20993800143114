import { useEffect, useState } from "react";
import { Root } from "../tables/vatiables/tableDataCheck";
import CheckTable from "./components/CheckTable";
import GenerateKey from "./components/GenerateKey";
import InputResetTrial from "./components/InputResetTrial";

const FixUser = () => {
  const [users, setUsers] = useState<any>([]);
  const [someUsers] = useState<any>([]);
  const loadUser = async () => {
    let response = await fetch(process.env.REACT_APP_API_URL + "userTrial")
    let response_json: Root = await response.json();
    if (response_json.status === "success") {
      const result = response_json.data
      const user = result.filter((item: any) => item.apiKey);
      setUsers(user);
    }
  };
  useEffect(() => {
    loadUser();
  }, [someUsers]);
  return (
    <div className="mx-auto mt-3 flex h-full items-center justify-center">
      <div className="grid h-full grid-cols-1">
        <div className="col-span-1 mx-auto h-full w-full rounded-xl sm:w-96 md:w-96 lg:w-96 xl:w-96 2xl:col-span-1">
          <GenerateKey />
        </div>
        <div className="col-span-1 mx-auto h-full w-full rounded-xl sm:w-96 md:w-96 lg:w-96 xl:w-96 2xl:col-span-1">
          <InputResetTrial />
        </div>
        <div className="grid grid-cols-1 gap-5 xl:grid-cols-1">
          <div>
            <CheckTable tableData={users} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixUser;
