import Card from "components/card";
import Swal from "sweetalert2";

const GenerateKey = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleCopyKey = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey);

    // Display Toast without closing the main Swal.fire
    Toast.fire({
      icon: "success",
      title: "คัดลอกคีย์เรียบร้อย!",
    }).then(() => {
      window.location.href = "/admin/generate-key";
    });
  };

  const handleSubmit = () => {
    fetch(process.env.REACT_APP_API_URL + "generate-api-key", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "success") {
          Swal.fire({
            title: "เรียบร้อย",
            html: `
              <div style="display: flex; align-items: center; justify-content: center;">
                <span id="keyText" style="cursor: pointer;">Key: <strong>${json.apiKey}</strong></span>
                <button id="copyButton" style="cursor: pointer;">
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            `,
            icon: "success",
            confirmButtonText: "ปิด",
            didOpen: () => {
              const copyButton = document.getElementById("copyButton");
              const keyText = document.getElementById("keyText");

              // Attach copy functionality to both keyText and copyButton
              if (copyButton || keyText) {
                const handleCopy = () => handleCopyKey(json.apiKey);
                copyButton.addEventListener("click", handleCopy);
                keyText.addEventListener("click", handleCopy);
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/admin/generate-key";
            }
          });
          return;
        } else {
          Swal.fire({
            title: "เกิดข้อผิดพลาด!",
            text: "สร้างคีย์ไม่สำเร็จ",
            icon: "error",
            confirmButtonText: "ปิด",
          });
          return;
        }
      });
  };

  return (
    <Card extra={"w-full px-6"}>
      <div className="w-full xl:overflow-x-hidden">
        <button
          className="linear mb-5 mt-4 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-red-700"
          onClick={handleSubmit}
        >
          สร้างคีย์
        </button>
      </div>
    </Card>
  );
};

export default GenerateKey;
